import React from 'react'

import styles from './office-list.module.css'
import {formatPhoneNumber} from '../lib/helpers.js'

function OfficeList (props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => (
            <li className={styles.gridItem}>
              <h2>{node.title}</h2>
              <div className={styles.mapWrapper}>
                <iframe 
                  src={node.googleMapsLink}
                  width="100%" 
                  height="300px"
                  allowfullscreen="" 
                  frameborder="0">
                </iframe>
              </div>
              <h3>Besøksadresse:</h3>
              <p>
                {node.visitorAddress}<br />
                {node.visitorPostNumber}
              </p>
              <h3>Postadresse:</h3>
              <p>
                {node.mailAddress}<br />
                {node.mailPostNumber}
              </p>
              <p>{formatPhoneNumber(node.phoneNumber)}</p>
            </li>
          ))}
      </ul>
    </div>
  )
}

OfficeList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default OfficeList
