import React from 'react'

import styles from './contact-form.module.css'
import {cn} from '../lib/helpers'
import { responsiveTitle2, micro } from './typography.module.css'


function ContactForm() {
  return (
    <div className={cn(styles.root, micro)}>
      <h2 className={responsiveTitle2}>Kontaktskjema</h2>
      <form name="contact" netlify netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label>NAVN
            <p>
              <input className={styles.formField} type="text" name="name" />
            </p>
          </label>
        </p>
        <p>
          <label>E-POST
            <p>
              <input className={styles.formField} type="email" name="email" />
            </p>
          </label>
        </p>
        <p>
          <label>MELDING
            <p>
              <textarea className={cn(styles.formField, styles.textArea)} name="message">
              </textarea>
            </p>
          </label>
        </p>
        <p>
          <button className={styles.button} type="submit">Send</button>
        </p>
      </form>
    </div>
  )
}

export default ContactForm
