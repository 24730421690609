import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import OfficeList from '../components/office-list'
import ContactForm from '../components/contact-form'

import {responsiveTitle1, linkStyle} from '../components/typography.module.css'

export const query = graphql`
  query OfficesPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      companyEmail
    }
    offices: allSanityOffice{
      edges {
        node  {
          id
          title
          visitorAddress
          visitorPostNumber
          mailAddress
          mailPostNumber
          phoneNumber
          googleMapsLink
        }
      }
    }
  }
`

const OfficesPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const officeNodes = data && data.offices && mapEdgesToNodes(data.offices)

  return (
    <Layout>
      <SEO title='Kontakt oss' />
      <Container>
        <h1 className={responsiveTitle1}>Kontakt oss</h1>
        <p>Velkommen til en avklarende samtale hvor vi både kan se på behov, ulike løsninger, og gi deg et godt tilbud.</p>
        <p>Du kan alltid nå oss på vår e-post: <a className={linkStyle} href={"mailto:" + site.companyEmail}>{site.companyEmail}</a></p>
        <p>Ta gjerne også en tur innom et av våre kontorer!</p>
        {officeNodes && officeNodes.length > 0 && <OfficeList nodes={officeNodes} />}
        <ContactForm />
      </Container>
    </Layout>
  )
}

export default OfficesPage
